import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Section from "../components/layout/section";

import * as styles from "./policy.module.scss";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { SEOv2 } from "../components/seov2";

const PrivacyPolicyPage = ({ data }) => (
  <Layout>
    <Section type="white">
      <div>
        <ReactMarkdown className={styles.markdown}>
          {
            data.allStrapiPrivacyPolicy.edges[0].node.privacyPolicy.data
              .privacyPolicy
          }
        </ReactMarkdown>
      </div>
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allStrapiPrivacyPolicy {
      edges {
        node {
          privacyPolicy {
            data {
              privacyPolicy
            }
          }
        }
      }
    }
  }
`;

export default PrivacyPolicyPage;

export const Head = () => (
  <SEOv2 title="Политика за поверителност">
    <meta name="robots" content="noindex" />
  </SEOv2>
);
